import InternalLink from '@components/utils/links/internalLink';
import styled from '@emotion/styled';
import Head from 'next/head';
import Image from 'next/legacy/image';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

const Button = styled(InternalLink)`
    width: clamp(20ch, 20%, 20rem);
    height: ${(props) => props.theme.spacing.xxl};
    margin: ${(props) => props.theme.spacing.md};
    margin-left: auto;
    margin-right: auto;
    border-radius: ${(props) => props.theme.spacing.xxl};
    align-self: center;

    font: ${(props) => props.theme.fonts.heading5};

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.primary.contrastText};

    &:active {
        transform: translateY(2px);
    }
`;
const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0px 20px 0px;
    padding: 0px 20px 0px 20px;
    width: 100%;
`;

const SImage = styled(Image)`
    border-radius: 200px;
    padding: 20px;
    max-width: 300px;
`;

const Text = styled.p`
    font: ${(props) => props.theme.fonts.heading4};
    text-align: center;
`;

function Custom404() {
    return (
        <Container>
            <Head>
                <title>404 Error</title>
                <meta name='robots' content='noindex' />
            </Head>
            <ImageContainer>
                <SImage
                    max-height='300px'
                    height='300'
                    src={'/assets/404_illustration.png'}
                    width='300'
                />
            </ImageContainer>
            <Text>{'The page you are looking for does not exist'}</Text>
            <Button href='/'>{'Home Page'}</Button>
        </Container>
    );
}

export default Custom404;
